<template>
  <a-modal
    width="650px"
    class="manual-geographic-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="active"
    @cancel="onExit">
    <h3 class="flex align-center txt-32 txt-bold">
      {{ $t('components.titles.manualAddLocation') }}
    </h3>
    <p>
      {{ $t('components.description.enterLatOrLng') }}
    </p>
    <GeographicAreaNameInput
      v-if="active"
      v-model="payload.name"
      :initial-name="initialName"
      :placeholder="$t('components.description.enterNameOfLocation')"
      class="mb-8"/>
    <div class="map-container">
      <div class="controls flex align-center">
        <a-input v-model="payload.lat" :disabled="isSupervisor" size="large" :placeholder="$t('values.latitude')" class="mr-16 w200"/>
        <a-input v-model="payload.lng" :disabled="isSupervisor" size="large" :placeholder="$t('values.longitude')" class="w200"/>
      </div>
      <l-map
        ref="myMap"
        class="map"
        :zoom="zoom"
        :center="center"
        @ready="ready"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        @click="onClick">
        <v-tilelayer-googlemutant
          :apikey="GOOGLE_MAPS_API_KEY"
          lang="en"
          region="en"/>
        <l-marker :draggable="!isSupervisor" :lat-lng="marker" :icon="icon" @update:latLng="onMove"/>
        <l-marker
          v-for="location in nearby"
          :key="location.place_id"
          :name="location.description"
          :disabled="isSupervisor"
          :lat-lng="location.latLng"
          :icon="manualIcon"
          @click="onComplete(location)">
            <l-tooltip>
              <div slot>{{ location.description }}</div>
            </l-tooltip>
          </l-marker>
      </l-map>
    </div>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24 w170"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24 w170"
        type="primary"
        size="large"
        :disabled="isInvalid || isSupervisor"
        @click="save">
        {{ $t('values.save') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import L, { latLng } from 'leaflet';
import { LMap, LMarker, LTooltip } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import 'leaflet/dist/leaflet.css';
import GeographicAreaNameInput from '../geographic-area-name-input/geographic-area-name-input.vue';
import { GOOGLE_MAPS_API_KEY } from '../../../../../util/map';

export default {
  name: 'ManualGeographicModal',
  components: {
    LMap,
    LMarker,
    LTooltip,
    GeographicAreaNameInput,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
  },
  props: {
    initialName: {
      type: String,
      required: false,
      default: ''
    },
    initialLat: {
      type: Number,
      required: true
    },
    initialLng: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    },
    getNearbyPlaces: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      GOOGLE_MAPS_API_KEY,
      payload: {
        name: this.initialName,
        lat: this.initialLat,
        lng: this.initialLng
      },
      nearby: [],
      icon: L.icon({
        iconUrl: '/marker.png',
        iconSize: [32, 37],
        iconAnchor: [16, 20]
      }),
      manualIcon: L.icon({
        iconUrl: '/manualMarker.png',
        iconSize: [32, 37],
        iconAnchor: [16, 20]
      }),
      zoom: 12,
      center: latLng(this.initialLat, this.initialLng),
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      marker: latLng(this.initialLat, this.initialLng)
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    isInvalid() {
      const { name, lng, lat } = this.payload;
      return !(name && lng && lat);
    }
  },
  watch: {
    active(newActive) {
      if (newActive) {
        this.payload.name = this.initialName;
        this.payload.lat = this.initialLat;
        this.payload.lng = this.initialLng;
        this.center = latLng(this.initialLat, this.initialLng);
        this.marker = latLng(this.initialLat, this.initialLng);
        this.updateNearbyPlaces({ lat: this.initialLat, lng: this.initialLng });
      }
    },
    payload: {
      // eslint-disable-next-line object-shorthand
      handler: function(newPayload) {
        this.marker = latLng(newPayload.lat, newPayload.lng);
        this.center = latLng(newPayload.lat, newPayload.lng);
      },
      deep: true
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      this.$refs.myMap.mapObject._onResize();
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    async updateNearbyPlaces(payload) {
      const newNearby = (await this.getNearbyPlaces(payload))
        .map(location => ({
          ...location,
          latLng: latLng(location.lat, location.lng)
        }))
        .filter(location => location.lat.toFixed(6) !== payload.lat.toFixed(6) && location.lng.toFixed(6) !== payload.lng.toFixed(6));
      this.nearby = newNearby;
    },
    async ready() {
      this.$refs.myMap.mapObject._onResize();
      this.updateNearbyPlaces({ lat: this.payload.lat, lng: this.payload.lng });
    },
    async onMove({ lat, lng }) {
      if (this.isSupervisor) {
        return;
      }
      this.payload.lat = lat;
      this.payload.lng = lng;
      this.updateNearbyPlaces({ lat, lng });
    },
    save() {
      this.onComplete({
        place_id: 'manual',
        ...this.payload
      });
    },
    onClick(ev) {
      if (this.isSupervisor) {
        return;
      }
      this.marker = ev.latlng;
    }
  }
};
</script>

<style lang="scss" scoped>
.map-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 6fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
  border: solid 1px #cccccc;
  background: #f4f4f4;
  height: 375px;
}

.controls {
  padding: 16px;
}

.map {
  border-top: solid 1px #cccccc;
  overflow: auto;
  width: 100%;
  height: 100%;
}
</style>
